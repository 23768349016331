<template>
   
    <!-- <nav role="navigation"> -->
 
        <ul class="breadcrumbs"  v-if="bcloaded"> 
            <li  v-for="(row,i) in breadbar" :key=i >
               <span  @click="compNofromEventNo(row.url,row.eventno)" :key="i" v-html="row.desc" ></span>
            </li>

        </ul>
    <!-- </nav> -->
</template>

<script>

import axios from 'axios';

    export default {
        name: "breadcrumb",
        data: function () {
            return {
                breadbar: [],
                bcloaded: false
            }    
        },
        mounted() {

            this.breadData();
        },
        methods: {
            breadData: function () {

                //get the routes of the URL
                let URL = window.location.href;
                var compParts = URL.split("/");
                compParts.splice(0, 3);
             

                //how many crumbs are there and what is the base URL
                var noComponents = compParts.length - 3;//3 because (i) zero based array and because dont want current event
                var href = process.env.VUE_APP_WEBSITE;

                //create variables for creating array of objects that provide info for breadcrumb
                var breadbarItem = { url: '', desc: '', eventno: '' };
                var breadbarItems = new Array();
                var i = 1;
           

                //create array
                for (i; i < noComponents; i++) {

                    breadbarItem.url = href;

                    switch (i) {
                        case 1:
                            //sport
                            breadbarItem.url = '/Sport/' + compParts[1] + "/All";

                            var sportsimg = "/assets/images/sports/svg/" + compParts[i]+ ".svg";

                            breadbarItem.desc = "<span  class='qsupersmallfont'><img class='show-for-medium' src='" + sportsimg  + "' style='width:14px;height:14px;margin-right:4px'//>" + compParts[i] + "</span>";

                            // breadbarItem.desc = "<div class='" + compParts[i] + "24' style='display:inline-flex;align-items: center'><span style='padding-left:30px'></div><div style='display:inline-flex;align-items: center;padding-left:5px'>" + compParts[i] + "</div>";
                            
                            break;
                        case 2:
                            // sport and country
                            breadbarItem.url = '/Sport/' + compParts[1] + '/' + compParts[2];

                            var countryimg = "/assets/images/flags/svg/" + compParts[i]+ ".svg";
                            
                            breadbarItem.desc = "<span ' class='qsupersmallfont'><img class='show-for-medium flagsmall' src='" + countryimg  + "' style='width:14px;height:14px;margin-right:4px'>" + compParts[i] + "</span>";
                            break;
                        case 3:
                            // sport, country and league 
                            breadbarItem.url = '/Competition/';
                            breadbarItem.desc = "<span class='qsupersmallfont' style='margin-top:2px'>" + this.removehyphens(compParts[i]) + "</span>";
                            breadbarItem.eventno = compParts[6];
                                          
                            break;
                        default:
                        // code block
                    }
                    //for (no; no <= i; no++) {
                    //    breadbarItem.url = breadbarItem.url + '/' + compParts[no];
                    //};

                    //add http component at start


                   

                    breadbarItems.push(JSON.parse(JSON.stringify(breadbarItem)));
                }


                //set array to vm data property and set flag to update
                this.breadbar = breadbarItems;



                this.bcloaded = true;
            
            },
            
            compNofromEventNo: function (URLPre, eventNo)
            {

                if (eventNo == '') {
                    //window.location.assign(URLPre);
                
                    this.$router.push(URLPre);
                } else {

                    axios.get(process.env.VUE_APP_WEBAPI  + "FeatureTables.svc/CompNofromEventNo", {

                        params: {
                            eventno: eventNo
                        }

                    }).then(response => {
                        var compNo = JSON.parse(response.data);

                        var URL = URLPre + compNo + '-1-1';

                        this.$router.push(URL);
                        //window.location.assign(URL);


                    });
                }
            }
        }
            
    };

    

</script>


<style>

.breadcrumbs li{
    
    cursor: pointer;

}
</style>