<template>
    <div v-if="data.length > 0">
        

        <!-- <div class="grid-x text-center" style="background-color: #0d0b38;padding:2px"> 
          <div class="cell small-2 navleft" @click="returnItem(-1)" ></div> 
          <div class="cell auto" id="datedisplayed" :style="{fontSize:fontsize + 'px'}" style="color:white;font-weight:bold;text-align:center">{{rowdesc}}</div>
          <div class="cell small-2 navright" @click="returnItem(1)" v-if="currentitem < data.length - 1"></div>  
        </div>  -->
        <h1 class="qmajorheader">
            <div style="display:inline-block" @click="returnItem(-1)" v-if="currentitem > 0"><span style="width:15px;height:15px;background-color:white;border-radius: 50%; display: inline-block;color: #0d0b38;font-size: 12px"><span style="margin-bottom:3px" class="qarrowsmall leftarrow qpointer" ></span></span></div>
            <div id="datedisplayed" :style="{fontSize:fontsize + 'px'}"  style="display: inline-block;padding: 0px 10px 0px 10px; font-weight:600">{{rowdesc}}</div>
            <div style="display:inline-block" @click="returnItem(1)" v-if="currentitem < data.length - 1"><span style="width:15px;height:15px;background-color:white;border-radius: 50%; display: inline-block;color: #0d0b38;font-size:12px"><span style="margin-bottom:3px" class="qarrowsmall rightarrow qpointer"></span></span></div>
        </h1>
    </div>
</template>

<script>
 export default {
        name: "flickthrough",
        data: function () {
            return {
                currentitem:0,
                itemsindata: 0,
                rowdesc: "",
                data: this.ftdata
            }
        },
        mounted() {
     
        
          this.rowdesc = this.removehyphens(this.data[0].D);
         
        },
        props: {
            ftdata: {type:Array, default: function(){}},
            fontsize: {type:Number, default: 12}

        },
        
        methods: {

            returnItem: function (offsetfromcurrentitem) { 

                this.currentitem = this.currentitem + offsetfromcurrentitem;
                //check if passed the end of the array if so then set to beginning
                this.rowdesc = this.removehyphens(this.data[this.currentitem].D);
               
                this.$emit("eventchange", {"o":this.data[this.currentitem].o, "d":this.rowdesc});
            }
        }
            }
</script>

