<template>
    <div>
        <template v-if="resultsloaded">

        <h3 class="qmajorheader qmediumboldfont qalignleft" v-if="header">{{   langConv('Team Form') }}</h3>    
                                
        <div class="grid-x qalignmiddle qsmallfont" style="min-height:20px" v-for="(re,i) in results[0].r" :key="i">
            <template v-if="i > 0 ">                                    
                <template v-if="results[0].r[i-1].team != re.team">
                    <div class="cell qsmallfontplus1 qaligncentre" style="margin:6px 0px 6px 4px">{{  re.team }}</div>
                    <div class="cell" style="height:6px"></div>
                </template>
            </template> 
            <template v-else>
                
                <div class="cell qsmallfontplus1 qaligncentre" style="margin:6px 0px 6px 4px">{{  re.team }} </div>
               
            </template>
   
            <template> 
               
                <div class="cell small-1 qsmallfont" style="text-align:center">{{ $displayDate(re.st,$dateformattype.daymonthshort) }}</div>
                <div class="cell small-4 qsmallfont" style="text-align: right;">{{re.t1}}</div>
                <div class="cell small-2" style="padding:0px 3px 0px 3px; text-align: center;">{{re.t1score}}-{{re.t2score}}</div>
                <div class="cell small-4 qsmallfont " style="text-align: left">{{re.t2}}</div>
                <span :class="re.res" class="cell shrink" style="height: 14px;width:14px;font-size: 8px;">{{re.res}}</span>
                
            </template>  

        </div>
        <div class="cell" style="height:14px"></div>

        </template>
    </div>
</template>


<script>

    import axios from 'axios';

    export default {
        name: "resultstable",
        data: function data() {
                return {
                    resultsloaded: false
                }
        },
        props: {
       
            eventid: {type: Number, default: 0 },
            header: {type: Boolean, default: false}
   
        },
        created() {

            this.loadResults();

        },
        methods: {


            loadResults: function () {

                this.resultsloaded = false

                axios.get(process.env.VUE_APP_WEBAPI  + "FeatureTables.svc/ResultsFromTPOID", {

                params: {
                    noitems: 10,
                    tpoid: this.eventid
                }

                }).then(response => {
                    this.results = JSON.parse(response.data);
                    if (this.results[0].r != undefined) { this.resultsloaded = true; this.$emit("resultsload"); this.results[0].r.length}
                });

            },

        }
    }
</script>

<style lang="scss" scoped>

</style>